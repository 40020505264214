/*UNE ANNONCE*/
.annonce {
    margin-top: 15%;
    margin-bottom: 15%;
    @include media-breakpoint-down(md) {
        margin-top: 50%;
    }
    @include media-breakpoint-up(md) {
        margin-top: 10%;
    }
}
.infosAnnonce {
    p {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    i {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
}
.modal_annonce {
    h4 {
        text-transform: capitalize;
    }
    p {
        text-transform: capitalize;
    }
    text-align: left;
    @include media-breakpoint-down(md) {
        margin-top: 5%;
    }
}
/*state*/
.badge-state1 {
    background-color: yellow;
    color: white;
}
.badge-state2 {
    background-color: blue;
    color: white;
}
.badge-state3 {
    background-color: brown;
    color: white;
}
.badge-state4 {
    background-color: green;
    color: white;
}
#infoDesk {
    @include media-breakpoint-down(md) {
        display: none;
    }
    background-color: white;
    color: black;
}
#infoMob {
    @include media-breakpoint-up(md) {
        display: none;
    }
}
.annonce_card {
    border: solid 46px;
    border-color: rgb(24, 10, 10);
    box-shadow: 0 14px 28px #fff, 0 10px 10px #d4dbde;
    border-radius: 60px 0px 60px 0px;
    @include media-breakpoint-down(md) {
        margin-bottom: 10%;
    }
}
.separator_annonce {
    border-width: 3px;
    border-color: $primary;
    width: 45%;
    height: 4px;
    border-style: solid;
    margin-bottom: 6%;
}
.icones_annonce {
    margin-bottom: 3%;
}
.fa-info-circle {
    color: $primary;
}
.fa-city {
    color: $primary;
}
.price {
    margin-top: 10%;
    @include media-breakpoint-up(md) {
        text-align: center;
        font-size: 30px;
    }
    @include media-breakpoint-down(md) {
        text-align: center;
    }
}
/*Carousel annonce*/
.carousel-control-prev {
    background-color: none;
}
.carousel-control-next {
    background-color: none;
}
.accordion {
    .btn {
        width: 100%;
        margin: 0;
    }
    .fa-info-circle {
        font-size: 20px;
    }
    .card-header {
        padding: 0px;
    }
}
/*Liste des annonces*/
.btnAdd {
    text-align: center;
    .btn {
        background-color: #484848;
        border: none;
    }
}
.btn-filtre1 {
    width: 100%;
    background-color: $primary;
    color: white;
}
.btn-filtre2 {
    width: 100%;
    border: 1px solid;
    border-color: black;
}
.btn-third {
    background-color: #e0dede;
}
.annonces {
    @include media-breakpoint-up(leg) {
        margin-bottom: 5%;
    }
    @include media-breakpoint-down(md) {
        margin-top: 20%;
    }
    @include media-breakpoint-up(md) {
        margin-top: 5%;
    }
    h1 {
        color: $primary;
        .barre {
            width: 12%;
            background-color: #7f181a;
            height: 6px;
        }
    }
}
.logoAnnonces {
    padding: 20%;
}
.lesAnnonces {
    .card-body {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        background-color: rgba(0, 0, 0, 0.01);
        border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
    .cardImg {
        padding: 0;
        height: 200px;
        overflow: hidden;
        background-color: white;
        @include media-breakpoint-down(md) {
            height: 150px;
        }
    }
    .btn {
        width: 100%;
    }
    .card-title {
        margin: 0;
    }
    .img-fluid {
        padding: 5%;
    }
}
.testA {
    background-color: white;
}
/*Annonces des débarrassés*/
#filtre {
    opacity: 0.2;
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.card_adsCustomer {
    z-index: 2;
    width: 100%;
    margin-top: 3%;
    h4 {
        text-transform: uppercase;
    }
    .card-header {
        padding-left: 2rem;
        padding-right: 2rem;
        border-bottom: 0px;
    }
    .card-body {
        article {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
    }
    .card-footer {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}
/*Effet grisé sur images*/
.bloc-titre-overlay {
    height: 150px;
    position: relative;
    p {
        background: none;
        color: white;
    }
    .text-center {
        padding-top: 5%;
    }
    .text-left {
        padding-top: 5%;
    }
}
.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse farthest-corner at center center, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.85) 100%) repeat scroll 0% 0%;
    -webkit-transition: 500ms;
    transition: 500ms;
}
.background-overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse farthest-corner at center center, rgba(79, 14, 15, 0.43) 20%, rgba(0, 0, 0, 0.7) 100%) repeat scroll 0% 0%;
    -webkit-transition: 500ms;
    transition: 500ms;
}
.debarrasser {
    background: url(/assets/themes/front/dist/images/slider.e50ea5d2.png);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 1000ms;
    transition: -webkit-transform 1000ms;
    transition: transform 1000ms;
    transition: transform 1000ms, -webkit-transform 1000ms;
    background-position: 50% 50%;
    background-size: cover;
}
.debarrasser2 {
    background: url(/assets/themes/front/img/livraison.jpg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 1000ms;
    transition: -webkit-transform 1000ms;
    transition: transform 1000ms;
    transition: transform 1000ms, -webkit-transform 1000ms;
    background-position: 50% 50%;
    background-size: cover;
}
/*Effet sur l'annonce suivant le statut*/
.voile_grise {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #000000b0;
    .text-center {
        padding-top: 15%;
        color: white;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 300;
    }
}
.card_voile {
    position: relative;
}
.voile_grise_carousel {
    position: absolute;
    bottom: 0;
    height: 20%;
    width: 100%;
    background-color: #000000b0;
    .text-center {
        padding-top: 2%;
        color: white;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 300;
    }
}
