h1,
h2,
h3,
p {
  color: #0b3140;
  font-weight: 300;
}
body {
  font-family: "Montserrat", sans-serif;
}
a:hover {
  text-decoration: none;
}
.wrapper {
  margin-bottom: 50px;
}

.wrapper2 {
  padding: 20px 20px;
  @include media-breakpoint-up(lg) {
    padding: 0px 80px;
  }
}
/*HEADER*/
.separator {
  border-width: 2px;
  border-color: $primary;
  width: 20%;
  height: 3px;
  border-style: solid;
  margin-bottom: 2%;
}
.bloc_titre {
  @include media-breakpoint-down(md) {
    margin-top: 50%;
    margin-left: 3%;
  }
  @include media-breakpoint-up(md) {
    margin-top: 22%;
    margin-left: 3%;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 12%;
    margin-left: 3%;
  }
}
.chiffre-cle {
  background: url("../../../img/bg-chiffre-cle.png");
  padding: 10px 10px;
  margin: 0px;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    padding: 60px 100px;
  }
  .caption {
    background-color: rgba(127, 24, 26, 0.8);
    padding: 20px;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      padding: 40px;
    }
    p {
      color: #fff;
      font-size: 1rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
    .chiffre {
      font-size: 4rem;
      line-height: 7rem;
    }
    .circle-chiffre {
      height: 150px;
      width: 150px;
      text-align: center;
      border-radius: 200px;
      padding: 20px;
      border: 2px solid white;
      margin-right: auto;
      margin-left: auto;
      color: white;
    }
  }
  .btn-secondary {
    width: auto;
    padding: 10px 20px;
  }
}
/*CONCEPT*/
.img_concept {
  text-align: center;
  @include media-breakpoint-down(md) {
    margin-bottom: 10%;
  }
  img {
    @include media-breakpoint-up(md) {
      width: 60%;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
.concept {
  .btn-primary {
    padding: 7.7px 10px !important;
  }
}
.separator-concept {
  border-width: 2px;
  border-color: $primary;
  width: 15%;
  height: 2px;
  border-style: solid;
  margin-bottom: 5%;
}
.btn-100 {
  width: 100%;
}
/*CARD*/
.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  .btn {
    margin: 2%;
  }
}
.card-body {
  padding: 20px;
  p.card-text {
    color: #595b5b;
  }
}
.card-image {
  height: 225px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.card-title {
  color: $primary;
  margin: 10px 0px;
}
.bloc-titre {
  h2 {
    text-transform: uppercase;
    font-size: 1.8rem;
  }
  hr {
    border: 1px solid $primary;
    width: 15%;
    float: left;
    margin-top: 0;
  }
}
.ligne {
  border-bottom: 1px solid #dedede;
  padding: 15px 0px 0px 0px;
  .fa {
    color: $primary;
    font-size: 1rem;
  }
}
.userlogin {
  color: $primary;
  border-color: $primary;
}
.modal-dialog {
  max-width: 60%;
}
.fa {
  color: $primary;
  margin-right: 1%;
}
#modal_confirmation {
  margin-top: 10%;
}
.contact {
  .debarrasser {
    background: url(/assets/themes/front/img/contact.jpg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 1000ms;
    transition: -webkit-transform 1000ms;
    transition: transform 1000ms;
    transition: transform 1000ms, -webkit-transform 1000ms;
    background-position: 50% 50%;
    background-size: cover;
  }
}
.map {
  .h2 {
    color: white;
  }
}
.btnContact {
  width: 10%;
}
/*Partie menu*/
.itemsMenu {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}
.logoMobile {
  @include media-breakpoint-down(md) {
    text-align: center;
    margin-bottom: 2%;
  }
}
.baseline {
  color: $primary;
  font-size: 13px;
  margin-top: 1%;
}
.btnConnexion {
  background-color: white;
  color: black;
}

.items_footer{
  a:hover{
    color:black;
  }
}