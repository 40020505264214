.userProfile {
  text-transform: uppercase;
}

.cardEditProfile {
  width: 100%;
  .btn {
    width: 20%;
  }
  .d-flex {
    text-align: center;
  }
}
