.hubdebarras{
  @include media-breakpoint-up(lg) {
    line-height:5;
  }
  color:#0B3140;
}
/*menu*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: $primary;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  margin-bottom: 2%;
  text-decoration: none;
  font-size: 15px;
  color: white;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.img_header{
  background: url('../../../img/slider.png');
  background-size: cover;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    height:80vh;
  }
  .bloc-gauche{
    background-color: rgba(127,24,26,0.7);
    height: 65vh;
    color:#fff;
    padding: 20px;
    @include media-breakpoint-up(md) {
      height: 40vh;
    }
    @include media-breakpoint-up(xl) {
      padding: 150px;
      height: 80vh;
    }
    span{
      font-family: 'Dancing Script', cursive;
      font-size: 2rem;
      line-height: 3rem;
    }
    h2{
      text-transform: uppercase;
      font-size:2rem;
      color:#fff;
      line-height: 4rem;
      @include media-breakpoint-up(xl) {
        font-size:3rem;
      }
    }
    p{
      color:#fff;
    }
    .btn-secondary{
      background-color: $secondary;
      width: auto;
      text-transform: uppercase;
      font-weight: 300;
      padding: 10px 25px;
    }
  }
  .bloc-droite{
    background-color: rgba(0,0,0,0.7);
    height: 65vh;
    color:#fff;
    padding: 50px 20px;
    @include media-breakpoint-up(md) {
      height: 40vh;
    }
    @include media-breakpoint-up(xl) {
      padding: 150px 150px;
      height: 80vh;
    }
    span{
      font-family: 'Dancing Script', cursive;
      font-size: 2rem;
      line-height: 3rem;
    }
    h2{
      text-transform: uppercase;
      font-size:2rem;
      color:#fff;
      line-height: 4rem;
      @include media-breakpoint-up(xl) {
        font-size:3rem;
      }
    }
    p{
      color:#fff;
    }
    .btn-primary{
      background-color: $primary;
      width: auto;
      text-transform: uppercase;
      font-weight: 300;
      padding: 10px 25px;
    }
  }
}
.intro{
  margin-top:10%;
  margin-left:5%;
}
.uppercase{
  text-transform: uppercase;
}