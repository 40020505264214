.logo {
  max-height: 40px;
  margin-top: 15px;
}
.navbar-dark.navbar-mm {
  position: absolute;
  z-index: 1;
  @include media-breakpoint-up(md) {
    padding: 10px 165px;
  }
  .nav-item a {
    text-transform: uppercase;
    color: #000;
    @include media-breakpoint-up(xl) {
      color: #fff;
    }
  }
}
.navbar-hub {
  padding: 00px 20px;
  margin-bottom: 10px;
  @include media-breakpoint-up(lg) {
    padding: 00px 80px;
  }
  .btn-primary {
    width: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 5px 20px;
    text-transform: uppercase;
    font-weight: 300;
  }
  .btn-secondary {
    width: auto;
    border-top-left-radius: 0;
    padding: 5px 20px;
    text-transform: uppercase;
    font-weight: 300;
    border-top-right-radius: 0;
  }
  .btn-annonces {
    background-color: #303030;
    color: #fff;
    width: auto;
    border-top-left-radius: 0;
    padding: 5px 20px;
    text-transform: uppercase;
    font-weight: 300;
    border-top-right-radius: 0;
  }
}
.navbar-collapse.collapse.show {
  background-color: rgba(255, 255, 255, 0.8);
}
.navbar-toggler {
  border-color: $primary;
  background-color: $primary;
}
.navbar-collapse {
  a {
    color: #000;
    margin-left: 2%;
  }
  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    background: $primary;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after {
    width: 10%;
  }
}
