footer{
  height: 300px;
  padding-right: 80px!important;
  padding-left: 80px!important;
  padding-top: 20px;
  font-size:0.8rem;
  background-color: $primary;
  color:#fff;
  @include media-breakpoint-up(md) {
    height: 100px;
  }
  p{
    color:#fff;
  }
  a{
    color:#fff;
  }
}
.social-networks{
  list-style: none;
  padding:0;
  margin:0;
  text-align: center;
}

.social-networks li{
  display: inline-block;
  margin:0 10px;
}

.social-networks li a{
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 28px;
  background-color: $primary-color;
  color:#fff;
  text-align: center;
}

.footer-links{
  margin:0;
}

.footer-links li {
  padding: 5px 0px;
  list-style: none;
}

.footer-links a{
  text-decoration: none;
}

.footer-links a:before{
  @extend %fa-icon;
  @extend .fas;
  content: fa-content($fa-var-chevron-right);
  margin-right: 5px;
}