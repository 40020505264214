//Config variables
@import "includes/variables.scss";

// NPM BOOTSTRAP
@import "~bootstrap/scss/bootstrap.scss";

// NPM FONT AWESOME V5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

//Mixins
@import "includes/mixins/background-variant.scss";
@import "includes/mixins/font.scss";

//General style
@import "includes/general.scss";

//Pages
@import "includes/pages/annonce.scss";
@import "includes/pages/cms.scss";
@import "includes/pages/concept.scss";
@import "includes/pages/home.scss";
@import "includes/pages/profil.scss";

//Structure
@import "includes/structure/navbar/navbar.scss";
@import "includes/structure/footer.scss";
@import "includes/structure/header.scss";

//Fonts
@import "includes/fonts/roboto.scss";

// Elements
@import "includes/elements/badge.scss";
@import "includes/elements/buttons.scss";
@import "includes/elements/colors.scss";
@import "includes/elements/multiselect.scss";
@import "includes/elements/wizard.scss";

// Forms
@import "includes/forms/forms.scss";

// Specific pages style
@import "includes/plugins/devbridge-autocomplete.scss";

// Modules
@import "includes/modules/platform/platform.scss";
@import "includes/modules/cms/cms.scss";
