.badge-ad {
    color: white;
}

.badge-ad-init {
    background-color: #d6d8d9;
}

.badge-ad-pre_for_sale {
    background-color: #5295d4;
}

.badge-ad-for_sale {
    background-color: #004788;
}

.badge-ad-sold {
    background-color: #7f181a;
}

.badge-ad-reserved {
    background-color: #408a52;
}

.badge-ad-archived {
    background-color: black;
}
