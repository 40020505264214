.concept2{
  @include media-breakpoint-up(leg) {
    margin-bottom:5%;
  }
  @include media-breakpoint-down(md) {
    margin-top: 20%;
  }
  @include media-breakpoint-up(md) {
    margin-top: 2%;
  }
}
.concept{
    @include media-breakpoint-up(leg) {
      margin-bottom:25%;
    }
    @include media-breakpoint-down(md) {
      margin-top: 20%;
    }
    @include media-breakpoint-up(md) {
      margin-top: 2%;
    }
    h1{
      color: $primary;
      .barre{
        width: 12%;
        background-color:
        #7f181a;
        height: 6px;
      }
    }
  }
.test{
    background-image:url('../../../../front/img/Concept2.jpg');
    background-size:40%;
    background-repeat:no-repeat;
    background-position-x:85%;
    background-position-y:100%;
    position : relative;
}